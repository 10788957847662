import React from 'react'
import logo from '../assets/img/logo/logo.png'

export default function Preloader() {
  return (
    <div className="preloader">
      <div className="spinner"></div>
      <img src={logo} className='img-fluid logo' alt="" />
    </div>
  )
}
