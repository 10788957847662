import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import busSetup from '../assets/img/BUSINESS_SETUP_AND_REGISTRATION.png';
import genAcc from '../assets/img/GENERAL_ACCOUNTING_SERVICES.jpg';
import remAcc from '../assets/img/REMOTE_ACCOUNTING_SERVICES.png';
import finAn from '../assets/img/FINANCIAL_ANALYSIS.png';
import softWare from '../assets/img/SOFTWARE_IMPLEMENTATION_AND_TRAINING.jpg';
import endOfYr from '../assets/img/ENDOFYEARSIGNEDFINANCIALS.jpg';
import audit from '../assets/img/audit.webp';
import operation from '../assets/img/OPERATION_AND_STRATEGIC_ACTIVITIES.jpg';
import others from '../assets/img/TAX_SERVICE.jpg';

export default function Services() {
  return (
    <div>
        <NavBar/>
        <div className="about-banner mb-4">
          <h2>Our Services</h2>
        </div>
        <div className="container services-main py-3">
        <div className="row">
            <div className="col-sm-12 col-md-4 mb-4">
                <div class="card border-0 shadow">
                    <img src={busSetup} class="card-img-top img-fluid" alt="..."/>
                    <div class="card-body">
                        <h5 class="card-title text-center fw-bold">Business Set-up</h5>
                        <ul>
                            <li>Business Plan (full/summary)</li>
                            <li>Business Registration</li>
                            <li>Bank account creation</li>
                            <li>Value books creation</li>
                            <li>GRA & SSNIT Registrations</li><br /><br />
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 mb-4">
                <div class="card border-0 shadow">
                    <img src={genAcc} class="card-img-top img-fluid" alt="..."/>
                    <div class="card-body">
                        <h5 class="card-title text-center fw-bold">General Accounting</h5>
                        <ul>
                            <li>Daily record keeping</li>
                            <li>Monthly financial reports</li>
                            <li>Monthly financial insights</li>
                            <li>Monthly payroll processing and statutory payments</li>
                            <li>Filing and documentation</li><br />
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 mb-4">
                <div class="card border-0 shadow">
                    <img src={remAcc} class="card-img-top img-fluid" alt="..."/>
                    <div class="card-body">
                        <h5 class="card-title text-center fw-bold">Remote Accounting</h5>
                        <ul>
                            <li>Provision of template or software for data capturing</li>
                            <li>Provision of key steps in daily data capturing and routine online trainings</li>
                            <li>Weekly checks on data and corrections</li>
                            <li>Monthly financials</li>
                            <li>Monthly financial insights</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 mb-4">
                <div class="card border-0 shadow">
                    <img src={finAn} class="card-img-top img-fluid" alt="..."/>
                    <div class="card-body">
                        <h5 class="card-title text-center fw-bold">Financial Analysis</h5>
                        <ul>
                            <li>Budget</li>
                            <li>Budget - Actual Comparison</li>
                            <li>Periodic Performance Variance</li>
                            <li>Best and Worst Performing Products</li>
                            <li> Standard Levels (Inventory, pay-as-you-go expenses)</li>
                            <li>Etc.</li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 mb-4">
                <div class="card border-0 shadow">
                    <img src={softWare} class="card-img-top img-fluid" alt="..."/>
                    <div class="card-body">
                        <h5 class="card-title text-center fw-bold">Software Implementation and Training</h5>
                        <ul>
                            <li>Selection of Suitable Software</li>
                            <li>Purchase and Installation</li>
                            <li>Data and Process Setup</li>
                            <li>Training on Usage</li>
                            <li>Offsite Assistance</li><br />
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 mb-4">
                <div class="card border-0 shadow">
                    <img src={endOfYr} class="card-img-top img-fluid" alt="..."/>
                    <div class="card-body">
                        <h5 class="card-title text-center fw-bold">End-of-Year Signed Financials</h5>
                        <ul>
                            <li>Signed Financial Statement - Company</li>
                            <li>Signed Financial Statement - Sole Proprietorship/Partnership/NGO, etc.</li>
                            <li>2 Comb-Binded Copies</li><br /><br /><br />
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 mb-4">
                <div class="card border-0 shadow">
                    <img src={audit} class="card-img-top img-fluid" alt="..."/>
                    <div class="card-body">
                        <h5 class="card-title text-center fw-bold">Audited Financials</h5>
                        <ul>
                            <li>Signed and Audited Financial Statement - Company</li>
                            <li>Signed and Audited Financial Statement - Sole Proprietorship/Partnership/NGO, etc.</li>
                            <li>3 Comb-Binded Copies</li><br />
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 mb-4">
                <div class="card border-0 shadow">
                    <img src={operation} class="card-img-top img-fluid" alt="..."/>
                    <div class="card-body">
                        <h5 class="card-title text-center fw-bold">Operation and Strategic Activities</h5>
                        <ul>
                            <li>Inventory Management</li>
                            <li>Internal Controls</li>
                            <li>Financial Control</li><br /><br /><br />
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 mb-4">
                <div class="card border-0 shadow">
                    <img src={others} class="card-img-top img-fluid" alt="..."/>
                    <div class="card-body">
                        <h5 class="card-title text-center fw-bold">Other Services</h5>
                        <ul>
                            <li>Tax and Other Filings</li>
                            <li>Payroll and Slip Services</li>
                            <li>Register Creations</li>
                            <li>Provision of Office Supplies</li>
                            <li>Provision of Computer and Its Accessories</li>
                            <li>Etc.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Footer/>
    </div>
  )
}
