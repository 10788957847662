import React from 'react'

export default function CompanyContactDetails({ formData, setFormData }) {
    const natureOfOfficeSpace = [
        "Rented",
        "Free",
        "Other"
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'nature_of_office_space') {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };
  return (
    <div className='px-2'>
            <h3>Company Contact Details</h3>
            <p>The questions asked here relates to your Business</p>
            <form>
                <div className="row shadow p-2 rounded-4">
                    <div className="col-sm-12 col-md-6 mb-3">
                        <label className="form-label">Digital Address of Office</label>
                        <input 
                            type="text" 
                            className='form-control' 
                            name='digital_address_of_office' 
                            value={formData.digital_address_of_office} 
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mb-3">
                        <label className="form-label">House Number For Office</label>
                        <input 
                            type="text" 
                            className='form-control'
                            name='house_number_for_office'
                            value={formData.house_number_for_office}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mb-3">
                        <label className="form-label">Street Name For Office</label>
                        <input 
                            type="text" 
                            className='form-control'
                            name='street_name_for_office'
                            value={formData.street_name_for_office}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mb-3">
                        <label className="form-label">City of Office</label>
                        <input 
                            type="text" 
                            className='form-control'
                            name='city_of_office'
                            value={formData.city_of_office}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mb-3">
                        <label className="form-label">District of Office</label>
                        <input 
                            type="text" 
                            className='form-control'
                            name='district_of_office'
                            value={formData.district_of_office}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mb-3">
                        <label className="form-label">Region of Office</label>
                        <input 
                            type="text" 
                            className='form-control'
                            name='region_of_office'
                            value={formData.region_of_office}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mb-3">
                        <label className="form-label">Nature of Office Space</label>
                        {natureOfOfficeSpace.map((nature) => (
                            <div className="form-check" key={nature}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name='nature_of_office_space'
                                    value={nature}
                                    checked={formData.nature_of_office_space === nature}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label className="form-check-label">
                                    {nature}
                                </label>
                            </div>
                        ))}
                        {formData.nature_of_office_space === 'Other' && (
                            <input
                                type="text"
                                className='form-control mt-1'
                                name='other_nature_of_office_space'
                                value={formData.other_nature_of_office_space || ''}
                                onChange={handleInputChange}
                                placeholder='Please specify'
                            />
                        )}
                    </div>
                    {formData.nature_of_office_space === 'Rented' && (
                        <div className="col-sm-12 col-md-6 mb-3">
                            <label className="form-label">Name of Landlord (If rented)</label>
                            <input 
                                type="text" 
                                className='form-control'
                                name='name_of_landlord'
                                value={formData.name_of_landlord}
                                onChange={handleInputChange}
                            />
                        </div>
                    )}
                    
                    <div className="col-sm-12 col-md-6 mb-3">
                        <label className="form-label">Postal Address of Office</label>
                        <input 
                            type="text" 
                            className='form-control'
                            name='postal_address_of_office'
                            value={formData.postal_address_of_office}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mb-3">
                        <label className="form-label">Office Phone Number</label>
                        <input 
                            type="number" 
                            className='form-control'
                            name='office_phone_number'
                            value={formData.office_phone_number}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 mb-3">
                        <label className="form-label">Office Email Address</label>
                        <input 
                            type="email" 
                            className='form-control'
                            name='office_email_address'
                            value={formData.office_email_address}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
            </form>
        </div>
  )
}
