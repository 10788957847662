import React from 'react';
import NavBar from '../components/NavBar';
// import bannerImg from '../assets/img/bannerimg2.jpg';
// import action from '../assets/img/action.png';
import busSetup from '../assets/img/BUSINESS_SETUP_AND_REGISTRATION.png';
import genAcc from '../assets/img/GENERAL_ACCOUNTING_SERVICES.jpg';
import remAcc from '../assets/img/REMOTE_ACCOUNTING_SERVICES.png'
// import me from '../assets/img/me.JPG'
import Footer from '../components/Footer';

export default function Home() {
  return (
    <div>
        <NavBar/>
        <div className="main-banner">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-7 col-md-12 tagline">
                        <h1>Forget the Hassles, Master Your Business Hustle!</h1>
                        <p>
                            We facilitate your business registrations and documentation processes, providing financial and operational reports, providing financial insights, and transforming your business digitally.
                        </p>
                        <a href="/services" className='btn btn-success shadow mb-3'>Explore Our Services</a>
                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="banner-image">
                            {/* <img src={bannerImg} className='img-fluid' width={526} alt="" /> */}
                            <img src="https://i.imgur.com/FhyPoBx.jpeg" className='img-fluid' width={526} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="about-section"></div>
        <div className="about px-2 mb-5">
            <div className="container bg-white about-sub shadow">
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <h2 className='mb-4'>Confused by all the business chaos? Let's turn that mess into mastery!</h2>
                        <p>
                            EKS Consults is a consulting firm that specializes in providing the requisite documentation and processes for businesses both financially and operationally. We assist from setup stage, ensuring availability of needed financials in assessing your performance, providing control measures to mitigate risks and migrating your business to the digitalized world. Its target market consists mainly of small to medium businesses. 
                        </p>
                        <a href='/about' className='btn btn-success'>Read More</a>
                    </div>
                    <div className="col-lg-5 col-md-12 action">
                        <img src="https://i.imgur.com/89nm8Jx.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div className="why-choose mb-5">
            <div className="container">
                <h2 className='mb-4' >Why Choose us</h2>
                <div className="row">
                    <div className="col-sm-12 col-md-4 mb-4">
                        <div className="card border-0 shadow rounded-4 p-3 exp">
                            <div className="card-body text-center">
                                <h5 className="fw-bold">Experience Business Consultants</h5>
                                <p>Our team of seasoned professionals provide expert advice and strategic solutions to help businesses thrive and achieve their goals.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mb-4">
                        <div className="card border-0 shadow rounded-4 p-3 pre">
                            <div className="card-body text-center">
                                <h5 className="fw-bold">Physical and Remote presence</h5>
                                <p>Physically and remotely, we are available to support and collaborate with clients, ensuring flexibility and accessibility in our services.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mb-4">
                        <div className="card border-0 shadow rounded-4 p-3 timely">
                            <div className="card-body text-center">
                                <h5 className="fw-bold">Timely Service Delivery</h5>
                                <p>We ensure timely service delivery, meeting deadlines and exceeding client expectations with prompt and efficient solutions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="services">
            <div className="container">
                <h2>Our Services</h2>
                <div className="row">
                    <div className="col-sm-12 col-md-4 mb-3">
                        <div className="card border-0 shadow">
                            <img src={busSetup} className="card-img-top img-fluid" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title text-center fw-bold">Business Set-up</h5>
                                <ul>
                                    <li>Business Plan (full/summary)</li>
                                    <li>Business Registration</li>
                                    <li>Bank account creation</li>
                                    <li>Value books creation</li>
                                    <li>GRA & SSNIT Registrations</li><br /><br />
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mb-3">
                        <div className="card border-0 shadow">
                            <img src={genAcc} className="card-img-top img-fluid" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title text-center fw-bold">General Accounting</h5>
                                <ul>
                                    <li>Daily record keeping</li>
                                    <li>Monthly financial reports</li>
                                    <li>Monthly financial insights</li>
                                    <li>Monthly payroll processing and statutory payments</li>
                                    <li>Filing and documentation</li><br />
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mb-3">
                        <div className="card border-0 shadow">
                            <img src={remAcc} className="card-img-top img-fluid" alt="..."/>
                            <div className="card-body">
                                <h5 className="card-title text-center fw-bold">Remote Accounting</h5>
                                <ul>
                                    <li>Provision of template or software for data capturing</li>
                                    <li>Provision of key steps in daily data capturing and routine online trainings</li>
                                    <li>Weekly checks on data and corrections</li>
                                    <li>Monthly financials</li>
                                    <li>Monthly financial insights</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='text-end' >
                        <a href='/services' className="btn btn-success">View More</a>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="testimonials mb-5">
            <div className="container">
                <h2>Testimonials</h2>
                <div id="carouselExampleAutoplaying" className="carousel slide d-none d-lg-block" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active p-3">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 mb-3">
                                    <div className="card border-0 shadow">
                                        <div className="card-body">
                                            <h6 className='mb-3'>Working with ESK Consults has simplified our financial management with their expert services and user-friendly tools. Their accurate reports and regular checks have given us confidence in our financial health, allowing us to focus on growing our business.</h6>
                                            <div className="d-flex align-items-center client">
                                                <div className="image">
                                                    <img src={me} className='img-fluid' alt="" />
                                                </div>
                                                <div className="details">
                                                    <h6 className='mb-0'>Christian Edem Kpegah</h6>
                                                    <p className='mb-0'>Co-Founder ~ Techieszon</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 mb-3">
                                    <div className="card border-0 shadow">
                                        <div className="card-body">
                                            <h6 className='mb-3'>Working with ESK Consults has simplified our financial management with their expert services and user-friendly tools. Their accurate reports and regular checks have given us confidence in our financial health, allowing us to focus on growing our business.</h6>
                                            <div className="d-flex align-items-center client">
                                                <div className="image">
                                                    <img src={me} className='img-fluid' alt="" />
                                                </div>
                                                <div className="details">
                                                    <h6 className='mb-0'>Christian Edem Kpegah</h6>
                                                    <p className='mb-0'>Co-Founder ~ Techieszon</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 mb-3">
                                    <div className="card border-0 shadow">
                                        <div className="card-body">
                                            <h6 className='mb-3'>Working with ESK Consults has simplified our financial management with their expert services and user-friendly tools. Their accurate reports and regular checks have given us confidence in our financial health, allowing us to focus on growing our business.</h6>
                                            <div className="d-flex align-items-center client">
                                                <div className="image">
                                                    <img src={me} className='img-fluid' alt="" />
                                                </div>
                                                <div className="details">
                                                    <h6 className='mb-0'>Christian Edem Kpegah</h6>
                                                    <p className='mb-0'>Co-Founder ~ Techieszon</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item p-3">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 mb-3">
                                    <div className="card border-0 shadow">
                                        <div className="card-body">
                                            <h6 className='mb-3'>Working with ESK Consults has simplified our financial management with their expert services and user-friendly tools. Their accurate reports and regular checks have given us confidence in our financial health, allowing us to focus on growing our business.</h6>
                                            <div className="d-flex align-items-center client">
                                                <div className="image">
                                                    <img src={me} className='img-fluid' alt="" />
                                                </div>
                                                <div className="details">
                                                    <h6 className='mb-0'>Christian Edem Kpegah</h6>
                                                    <p className='mb-0'>Co-Founder ~ Techieszon</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 mb-3">
                                    <div className="card border-0 shadow">
                                        <div className="card-body">
                                            <h6 className='mb-3'>Working with ESK Consults has simplified our financial management with their expert services and user-friendly tools. Their accurate reports and regular checks have given us confidence in our financial health, allowing us to focus on growing our business.</h6>
                                            <div className="d-flex align-items-center client">
                                                <div className="image">
                                                    <img src={me} className='img-fluid' alt="" />
                                                </div>
                                                <div className="details">
                                                    <h6 className='mb-0'>Christian Edem Kpegah</h6>
                                                    <p className='mb-0'>Co-Founder ~ Techieszon</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 mb-3">
                                    <div className="card border-0 shadow">
                                        <div className="card-body">
                                            <h6 className='mb-3'>Working with ESK Consults has simplified our financial management with their expert services and user-friendly tools. Their accurate reports and regular checks have given us confidence in our financial health, allowing us to focus on growing our business.</h6>
                                            <div className="d-flex align-items-center client">
                                                <div className="image">
                                                    <img src={me} className='img-fluid' alt="" />
                                                </div>
                                                <div className="details">
                                                    <h6 className='mb-0'>Christian Edem Kpegah</h6>
                                                    <p className='mb-0'>Co-Founder ~ Techieszon</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="carouselExampleAutoplaying" className="carousel slide d-lg-none" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active p-3">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 mb-3">
                                    <div className="card border-0 shadow">
                                        <div className="card-body">
                                            <h6 className='mb-3'>Working with ESK Consults has simplified our financial management with their expert services and user-friendly tools. Their accurate reports and regular checks have given us confidence in our financial health, allowing us to focus on growing our business.</h6>
                                            <div className="d-flex align-items-center client">
                                                <div className="image">
                                                    <img src={me} className='img-fluid' alt="" />
                                                </div>
                                                <div className="details">
                                                    <h6 className='mb-0'>Christian Edem Kpegah</h6>
                                                    <p className='mb-0'>Co-Founder ~ Techieszon</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item p-3">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 mb-3">
                                    <div className="card border-0 shadow">
                                        <div className="card-body">
                                            <h6 className='mb-3'>Working with ESK Consults has simplified our financial management with their expert services and user-friendly tools. Their accurate reports and regular checks have given us confidence in our financial health, allowing us to focus on growing our business.</h6>
                                            <div className="d-flex align-items-center client">
                                                <div className="image">
                                                    <img src={me} className='img-fluid' alt="" />
                                                </div>
                                                <div className="details">
                                                    <h6 className='mb-0'>Christian Edem Kpegah</h6>
                                                    <p className='mb-0'>Co-Founder ~ Techieszon</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item p-3">
                            <div className="row">
                                <div className="col-sm-12 col-md-4 mb-3">
                                    <div className="card border-0 shadow">
                                        <div className="card-body">
                                            <h6 className='mb-3'>Working with ESK Consults has simplified our financial management with their expert services and user-friendly tools. Their accurate reports and regular checks have given us confidence in our financial health, allowing us to focus on growing our business.</h6>
                                            <div className="d-flex align-items-center client">
                                                <div className="image">
                                                    <img src={me} className='img-fluid' alt="" />
                                                </div>
                                                <div className="details">
                                                    <h6 className='mb-0'>Christian Edem Kpegah</h6>
                                                    <p className='mb-0'>Co-Founder ~ Techieszon</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <Footer/>
    </div>
  )
}
