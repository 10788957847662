import React from 'react'

export default function DirectorSecretaryDetails({ formData, setFormData }) {
    const sex = [
        "Male",
        "Female"
    ];
    const options = [
        "Yes",
        "No"
    ];
    const secretaryEligibility =[
        "Professional Qualification",
        "Tertiary level Qualification",
        "Baristor or Solicitor",
        "Chartered Accountant",
        "Chartered Secretary",
        "We will contract EKS Consults",
    ]
    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        if (name === 'gender' || name === 'eligibility_as_secretary') {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else if (name === 'insolventCompany' || name === 'fraudConviction' || name === 'insolventCompanyConviction') {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };
    
  return (
    <div className='px-2'>
        <h3>Directors and Secretary Personal Details Section (2 or more persons for director)</h3>
        <p>The questions here relates to the Directors and Secretary as well as Subscribers who are not Directors</p>
        <form>
            

            <div className="row shadow p-2 rounded-4">
                <div className="col-sm-12 col-md-6 mb-3">
                <label className="form-label">Eligibility as Director</label>

                    {/* Question 1 */}
                    <div className="mb-3 d-flex align-items-center">
                        <label className='lg-label' >Are you a senior manager of a Company that has become insolvent in the past 5 years?</label>
                        {options.map((option) => (
                            <div className="form-check" key={option}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="insolventCompany"
                                    value={option}
                                    checked={formData.insolventCompany === option}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label className="form-check-label me-2">
                                    {option}
                                </label>
                            </div>
                        ))}
                    </div>

                    {/* Question 2 */}
                    <div className="mb-3 d-flex align-items-center">
                        <label className='lg-label'>Charged with or convicted of a criminal offence involving fraud or dishonesty?</label>
                        {options.map((option) => (
                            <div className="form-check" key={option}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fraudConviction"
                                    value={option}
                                    checked={formData.fraudConviction === option}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label className="form-check-label me-2">
                                    {option}
                                </label>
                            </div>
                        ))}
                    </div>

                    {/* Question 3 */}
                    <div className="mb-3 d-flex align-items-center">
                        <label className='lg-label'>Charged with or convicted of a criminal offence relating to the promotion, incorporation or management of an insolvent company?</label>
                        {options.map((option) => (
                            <div className="form-check" key={option}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="insolventCompanyConviction"
                                    value={option}
                                    checked={formData.insolventCompanyConviction === option}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label className="form-check-label me-2">
                                    {option}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Eligibility as Secretary</label>
                    {secretaryEligibility.map((sec) => (
                        <div className="form-check" key={sec}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name='eligibility_as_secretary'
                                value={sec}
                                checked={formData.eligibility_as_secretary === sec}
                                onChange={handleInputChange}
                                required
                            />
                            <label className="form-check-label">
                                {sec}
                            </label>
                        </div>
                    ))}
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">First Name</label>
                    <input 
                        type="text" 
                        className='form-control' 
                        name='first_name' 
                        value={formData.first_name} 
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Middle Name</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='middle_name'
                        value={formData.middle_name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Last Name</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='last_name'
                        value={formData.last_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Former Name (if any)</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='former_name'
                        value={formData.former_name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Gender</label>
                    {sex.map((sx) => (
                        <div className="form-check" key={sx}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name='gender'
                                value={sx}
                                checked={formData.gender === sx}
                                onChange={handleInputChange}
                                required
                            />
                            <label className="form-check-label">
                                {sx}
                            </label>
                        </div>
                    ))}
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Date of Birth</label>
                    <input 
                        type="date" 
                        className='form-control'
                        name='dob'
                        value={formData.dob}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Place of Birth</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='place_of_birth'
                        value={formData.place_of_birth}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Nationality</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='nationality'
                        value={formData.nationality}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Occupation</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='occupation'
                        value={formData.occupation}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Phone Number</label>
                    <input 
                        type="number" 
                        className='form-control'
                        name='phone_number'
                        value={formData.phone_number}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Email Address</label>
                    <input 
                        type="email" 
                        className='form-control'
                        name='email_address'
                        value={formData.email_address}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Tin Number</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='director_tin_number'
                        value={formData.director_tin_number}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Ghana Card Number</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='director_ghana_card_number'
                        value={formData.director_ghana_card_number}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Digital Address</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='digital_address'
                        value={formData.digital_address}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">House Number</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='house_number'
                        value={formData.house_number}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Street Name</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='street_name'
                        value={formData.street_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">City</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='city'
                        value={formData.city}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">District</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='district'
                        value={formData.district}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Region</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='region'
                        value={formData.region}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Country</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='country'
                        value={formData.country}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Mother's Maiden Name (Complete if you do not have TIN)</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='mother_maiden_name'
                        value={formData.mother_maiden_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
            </div>
        </form>
    </div>
  )
}
