import React, { useEffect, useState } from 'react'
import NavBarDash from '../components/NavBarDash'
import { db } from '../firebase';
import ReactPaginate from 'react-paginate';
import { get, ref } from 'firebase/database';
import Preloader from '../components/Preloader';
import { IconButton } from '@mui/material';
import { DeleteOutline, VisibilityOutlined } from '@mui/icons-material';
import { handleSoleView, deleteSoleDataById } from '../firebase';

export default function SoleProprietorship() {
    const [isLoading, setIsLoading] = useState(false);
    const itemsPerPage = 7; 
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedData, setSelectedData] = useState(null);
    const [soleProprietorshipData, setSoleProprietorshipData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const fetchSoleProprietorshipData = async () => {
        const usersRef = ref(db, 'sole');
        setIsLoading(true);
        try {
            const snapshot = await get(usersRef);
            if (snapshot.exists()) {
                const soleData = snapshot.val();
                setSoleProprietorshipData(Object.values(soleData));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        fetchSoleProprietorshipData();
    }, []);

    const handleViewClick = (id) => {
        handleSoleView(id, setSelectedData);
    };

    const handleDelete = (user_id) => {
        deleteSoleDataById(user_id);
        fetchSoleProprietorshipData()
        window.location.reload();
    }

    const sortedData = soleProprietorshipData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedData = soleProprietorshipData.slice(startIndex, endIndex);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

      const shouldDisplayPagination = soleProprietorshipData.length > itemsPerPage;

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = sortedData.filter(item => 
        item.proposed_business_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  return (
    <div>
        <NavBarDash/>
        {isLoading && <Preloader />}
        <div className="container py-3">
            <h2>Sole Proprietorship Registrations</h2>
            {displayedData.length === 0 ? (
                <h6 className='text-center' >No data to display</h6>
            ):(
                <>
                <div className="mb-3">
                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder="Search by Proposed Business Name..." 
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
                    <div className='table-responsive'>
                        <table className='table table-striped table-hover'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Proposed Business Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {filteredData.map((item, index) => {
                                        return(
                                            <tr key={index} className='align-middle'>
                                                <td>{item.first_name +" "+ item.last_name}</td>
                                                <td>{item.email_address}</td>
                                                <td>{item.phone_number}</td>
                                                <td>{item.proposed_business_name}</td>
                                                <td>
                                                <IconButton onClick={() => handleDelete(item.user_id)}  ><DeleteOutline style={{color: '#ffd700'}} /></IconButton>
                                                <IconButton onClick={() => handleViewClick(item.user_id)} data-bs-toggle="modal" data-bs-target="#staticBackdrop" ><VisibilityOutlined style={{color: '#008000'}} /></IconButton>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        {shouldDisplayPagination && (
                            <ReactPaginate
                                pageCount={Math.ceil(soleProprietorshipData.length / itemsPerPage)}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                containerClassName="pagination"
                                activeClassName="active"
                            />
                        )}
                    </div>
                </>
            ) }

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                <div className="modal-header">
                {selectedData && (
                    <h1 className="modal-title fs-5" id="staticBackdropLabel"> {selectedData.proposed_business_name} Registration Details </h1>
                )}
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                {!selectedData ? (
                    <Preloader />
                ) : (
                    <div className='table-responsive'>
                        <table className='table my-table'>
                            <thead>
                                <tr>
                                    <th colSpan="2">Cover Note Section</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Proposed Business Name</td>
                                    <td>{selectedData.proposed_business_name || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>TIN Number</td>
                                    <td>{selectedData.tin_number || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Ghana Card Number</td>
                                    <td>{selectedData.ghana_card_number || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Nature of Business</td>
                                    <td>{selectedData.nature_of_business || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Date of Commencement</td>
                                    <td>{selectedData.date_of_commencement || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <th colSpan="2">Business Related Questions</th>
                                </tr>
                                <tr>
                                    <td>Digital Address of Office</td>
                                    <td>{selectedData.digital_address_of_office || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>House Number for Office</td>
                                    <td>{selectedData.house_number_for_office || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Street Name for Office</td>
                                    <td>{selectedData.street_name_for_office || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>City of Office</td>
                                    <td>{selectedData.city_of_office || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>District of Office</td>
                                    <td>{selectedData.district_of_office || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Region of Office</td>
                                    <td>{selectedData.region_of_office || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Nature of Office Space</td>
                                    <td>{selectedData.nature_of_office_space || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Other Nature of Office Space</td>
                                    <td>{selectedData.other_nature_of_office_space || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Name of Landlord</td>
                                    <td>{selectedData.name_of_landlord || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Postal Address of Office</td>
                                    <td>{selectedData.postal_address_of_office || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Office Phone Number</td>
                                    <td>{selectedData.office_phone_number || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Office Email Address</td>
                                    <td>{selectedData.office_email_address || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Projected Revenue</td>
                                    <td>{selectedData.projected_revenue || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Projected Number of Employees</td>
                                    <td>{selectedData.projected_employees_number || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <th colSpan="2">Proprietor Personal Details</th>
                                </tr>
                                <tr>
                                    <td>First Name</td>
                                    <td>{selectedData.first_name || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Last Name</td>
                                    <td>{selectedData.last_name || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Middle Name</td>
                                    <td>{selectedData.middle_name || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Former Name</td>
                                    <td>{selectedData.former_name || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Gender</td>
                                    <td>{selectedData.gender || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Date of Birth</td>
                                    <td>{selectedData.dob || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Nationality</td>
                                    <td>{selectedData.nationality || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Occupation</td>
                                    <td>{selectedData.occupation || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Phone Number</td>
                                    <td>{selectedData.phone_number || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Email Address</td>
                                    <td>{selectedData.email_address || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Digital Address</td>
                                    <td>{selectedData.digital_address || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>House Number</td>
                                    <td>{selectedData.house_number || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Street Name</td>
                                    <td>{selectedData.street_name || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>{selectedData.city || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>District</td>
                                    <td>{selectedData.district || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Region</td>
                                    <td>{selectedData.region || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Country</td>
                                    <td>{selectedData.country || 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Mother's Maiden Name</td>
                                    <td>{selectedData.mother_maiden_name || 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}
