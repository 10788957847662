import React, { useState } from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { Email, Phone, WhatsApp } from '@mui/icons-material'
import shaking from '../assets/img/shaking.jpg'
import ToastService from 'react-material-toast';
import Preloader from '../components/Preloader'

const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });


export default function Contact() {
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userMessage, setUserMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const baseURL = 'https://eks-email-server.onrender.com';
    

    const sendEmail = async (userName, userEmail, userPhone, userMessage, e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            var templateParams = {
                name: userName,
                email: userEmail,
                phone: userPhone,
                message: userMessage
            };
    
            const response = await fetch(`${baseURL}/api/send-email-enquiry`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(templateParams),
            });
    
            if (!response.ok) {
                toast.error("Message Not Sent!");
            } else {
                setUserName("");
                setUserEmail("");
                setUserPhone("");
                setUserMessage("");
                toast.success("Message Sent Successfully!!");
            }
        } catch (error) {
            console.error('Error occurred while sending email:', error);
        }finally{
            setIsLoading(false)
        }
    };

  return (
    <div>
        <NavBar/>
        {isLoading && <Preloader />}
        <div className="about-banner mb-4">
          <h2>Contact Us</h2>
        </div>
        <div className="container py-5">
            <div className="contact-form">
                <div className="row">
                <div className="col-sm-4">
                    <div className="mb-3">
                    <h6  ><Phone/> <span><a href="tel:+233248171348" className='text-dark text-decoration-none'> +233 248 171 348</a></span> </h6>
                    </div>
                    <div className="mb-3">
                    <h6  ><WhatsApp /><span><a className='text-dark text-decoration-none' href="https://wa.me/0248171348" target="_blank" rel="noreferrer"> +233 248 171 348</a></span> </h6>
                    </div>
                    <div className="mb-3">
                    <h6  ><Email/> <span><a className='text-dark text-decoration-none' href="mailto:info@eksconsults.com"> info@eksconsults.com</a></span> </h6>
                    </div>
                    <div className="mb-3">
                    <h6  ><Email/> <span><a className='text-dark text-decoration-none' href="mailto:eksbus@gmail.com"> eksbus@gmail.com</a></span> </h6>
                    </div>
                    <div className="shaking-hands" >
                    <img src={shaking} className="img-fluid" alt="" />
                    </div>
                </div>
                <div className="col-sm-8">
                    <form className='shadow p-3 rounded-3' onSubmit={(e) => sendEmail(userName, userEmail, userPhone, userMessage, e)}  >
                        <div className="row">
                            <div className="col-sm mt-2">
                            <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} className="form-control" placeholder="Your Name" required />
                            </div>
                            <div className="col-sm mt-2">
                            <input type="number" value={userPhone} onChange={(e) => setUserPhone(e.target.value)} className="form-control" placeholder="Your Phone Number" required />
                            </div>
                        </div>
                        <input type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} className="form-control mt-2" placeholder="Your Email" required />
                        <textarea value={userMessage}  onChange={(e) => setUserMessage(e.target.value)} cols="30" rows="10" className="form-control mt-2" placeholder="Your Message" required></textarea>
                    
                        <button className="btn btn-success mt-2 w-100" type="submit"  >Send Message</button>
                    </form>
                    
                </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}
