import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import CoverNoteSection from './CoverNoteSection';
import BusinessQuestions from './BusinessQuestions';
import ProprietorPersonalDetails from './ProprietorPersonalDetails';
import BeneficialOwners from './BeneficialOwners';
import { Home } from '@mui/icons-material';
import ToastService from 'react-material-toast';
import Preloader from '../components/Preloader';
import { useNavigate } from 'react-router-dom';
import { writeSoleProprietorship, writePartnership } from '../firebase';
import {v4} from "uuid";

const toast = ToastService.new({
    place: "topRight",
    duration: 1,
    maxCount: 1,
  });

export default function SoleMultiForm() {
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();
    const id = v4();
    const baseURL = 'https://eks-email-server.onrender.com';
    useEffect(() => {
        const hiddenButton = document.getElementById('hiddenModalTrigger');
        if (hiddenButton) {
            hiddenButton.click();
        }
    }, []);
    const [formData, setFormData] = useState({
        proposed_business_name: "",
        tin_number: "",
        ghana_card_number: "",
        nature_of_business: "",
        date_of_commencement: "",
        digital_address_of_office: "",
        house_number_for_office: "",
        street_name_for_office: "",
        city_of_office: "",
        district_of_office: "",
        region_of_office: "",
        nature_of_office_space: "",
        other_nature_of_office_space: "",
        name_of_landlord: "",
        postal_address_of_office: "",
        office_phone_number: "",
        office_email_address: "",
        projected_revenue: "",
        projected_employees_number: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        former_name: "",
        gender: "",
        dob: "",
        nationality: "",
        occupation: "",
        phone_number: "",
        email_address: "",
        digital_address: "",
        house_number: "",
        street_name: "",
        city: "",
        district: "",
        region: "",
        country: "",
        mother_maiden_name: "",
        beneficial_owner: "",
        tin_of_beneficial_owner: "",
        id_type_of_beneficial_owner: "",
        id_number_of_beneficial_owner: "",
        place_of_issue: "",
        date_of_issue: "",
        expiry_date: "",
        country_of_issue: "",
        full_name_of_beneficial_owner: "",
        gender_of_beneficial_owner: "",
        dob_of_beneficial_owner: "",
        mother_maiden_name_of_beneficial_owner: "",
    });
    const [screen, setScreen] = useState(0);
    const [formType, setFormType] = useState(null);

    const handleFormTypeSelection = (type) => {
        setFormType(type);
        setScreen(0);
    };

    const screenDisplay = () => {
        if (formType === 'sole') {
            if (screen === 0) {
                return <CoverNoteSection formData={formData} setFormData={setFormData} />;
            } else if (screen === 1) {
                return <BusinessQuestions formData={formData} setFormData={setFormData} />;
            } else if (screen === 2) {
                return <ProprietorPersonalDetails formData={formData} setFormData={setFormData} />;
            }
        } else if (formType === 'partnership') {
            if (screen === 0) {
                return <CoverNoteSection formData={formData} setFormData={setFormData} />;
            } else if (screen === 1) {
                return <BusinessQuestions formData={formData} setFormData={setFormData} />;
            } else if (screen === 2) {
                return <ProprietorPersonalDetails formData={formData} setFormData={setFormData} />;
            } else {
                return <BeneficialOwners formData={formData} setFormData={setFormData} />;
            }
        }
        return null;
    };

    const isLastScreen = () => {
        if (formType === 'sole') {
            return screen === 2; 
        } else if (formType === 'partnership') {
            return screen === 3; 
        }
        return false;
    };

    const validateForm = () => {
        const commonFields = 
            formData.proposed_business_name && 
            formData.ghana_card_number && 
            formData.nature_of_business &&
            formData.digital_address_of_office &&
            formData.house_number_for_office &&
            formData.street_name_for_office &&
            formData.city_of_office &&
            formData.district_of_office &&
            formData.region_of_office && 
            formData.nature_of_office_space &&
            formData.postal_address_of_office &&
            formData.office_phone_number &&
            formData.office_email_address &&
            formData.projected_revenue &&
            formData.projected_employees_number &&
            formData.last_name && 
            formData.gender &&
            formData.dob && 
            formData.nationality &&
            formData.occupation &&
            formData.phone_number &&
            formData.email_address &&
            formData.digital_address &&
            formData.house_number &&
            formData.street_name &&
            formData.city &&
            formData.district &&
            formData.region &&
            formData.country;

        if (formType === 'sole') {
            return commonFields 
        } else if (formType === 'partnership') {
            return commonFields &&
                formData.full_name_of_beneficial_owner &&
                formData.tin_of_beneficial_owner &&
                formData.id_type_of_beneficial_owner &&
                formData.id_number_of_beneficial_owner &&
                formData.place_of_issue &&
                formData.date_of_issue &&
                formData.expiry_date &&
                formData.country_of_issue &&
                formData.gender_of_beneficial_owner &&
                formData.dob_of_beneficial_owner &&
                formData.mother_maiden_name_of_beneficial_owner;
        }
        return false;
    };

    const validateCoverNote = () => {
        return  formData.proposed_business_name && 
                formData.ghana_card_number && 
                formData.nature_of_business;
    };
    
    const validateBusinessQuestions = () => {
        return formData.digital_address_of_office &&
               formData.house_number_for_office &&
               formData.street_name_for_office &&
               formData.city_of_office &&
               formData.district_of_office &&
               formData.region_of_office && 
               formData.nature_of_office_space &&
               formData.postal_address_of_office &&
               formData.office_phone_number &&
               formData.office_email_address &&
               formData.projected_revenue &&
               formData.projected_employees_number;
    };
    
    const validateProprietorPersonalDetails = () => {
        return  formData.last_name && 
                formData.gender &&
                formData.dob && 
                formData.nationality &&
                formData.occupation &&
                formData.phone_number &&
                formData.email_address &&
                formData.digital_address &&
                formData.house_number &&
                formData.street_name &&
                formData.city &&
                formData.district &&
                formData.region &&
                formData.country;
    };
    
    const validateBeneficialOwners = () => {
        return formData.beneficial_owner &&
               formData.tin_of_beneficial_owner &&
               formData.id_type_of_beneficial_owner &&
               formData.id_number_of_beneficial_owner &&
               formData.place_of_issue &&
               formData.date_of_issue &&
               formData.expiry_date &&
               formData.country_of_issue &&
               formData.full_name_of_beneficial_owner &&
               formData.gender_of_beneficial_owner &&
               formData.dob_of_beneficial_owner &&
               formData.mother_maiden_name_of_beneficial_owner;
    };

    const sendSole = async (data) => {
        setIsLoading(true);
    
        try {
            const response = await fetch(`${baseURL}/api/sole-proprietorship`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
    
            if (!response.ok) {
                toast.error("Details Not Sent!");
            } else {
                toast.success("Details Sent Successfully!!");
                navigate('/confirmation')
            }
        } catch (error) {
            console.error('Error occurred while sending email:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const sendPart = async (data) => {
        setIsLoading(true);
    
        try {
            const response = await fetch(`${baseURL}/api/partnership`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
    
            if (!response.ok) {
                toast.error("Details Not Sent!");
            } else {
                toast.success("Details Sent Successfully!!");
                navigate('/confirmation')
            }
        } catch (error) {
            console.error('Error occurred while sending email:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    
    const handleSubmit = () => {
        if (!validateForm()) {
            toast.error("Please fill out all required fields.");
            return;
        }
    
        const dataToLog = { ...formData };
    
        if (formType === 'sole') {
            const {
                full_name_of_beneficial_owner,
                tin_of_beneficial_owner,
                id_type_of_beneficial_owner,
                id_number_of_beneficial_owner,
                place_of_issue,
                date_of_issue,
                expiry_date,
                country_of_issue,
                gender_of_beneficial_owner,
                dob_of_beneficial_owner,
                mother_maiden_name_of_beneficial_owner,
                ...soleData
            } = dataToLog;
            sendSole(soleData)
            writeSoleProprietorship(id, ...Object.values(soleData));
        } else if (formType === 'partnership') {
            sendPart(dataToLog)
            writePartnership(id, ...Object.values(dataToLog));
        }
    };
    
    return (
        <div>
            <NavBar />
            {isLoading && <Preloader />}
            <div className="about-banner mb-4">
                <h2>Business Registration Data Request Form</h2>
            </div>
            <div className="container py-5">
            {formType && (
                <h3 className='mb-3'>{formType === 'sole' ? 'Sole Proprietorship Registration Form' : 'Partnership Registration Form'}</h3>
            )}
                <div className='mb-3'>
                    {screenDisplay()}
                </div>
                {formType && (
                    <div className='d-flex align-items-center mb-3'>
                        <div className='me-1'>
                            {screen > 0 && (
                                <button
                                    onClick={() => setScreen(currScreen => currScreen - 1)}
                                    className="btn btn-warning"
                                >
                                    Back
                                </button>
                            )}
                        </div>
                        <button
                            onClick={() => {
                                if (isLastScreen()) {
                                    handleSubmit();
                                } else {
                                    if (screen === 0 && validateCoverNote()) {
                                        setScreen(currScreen => currScreen + 1);
                                    } else if (screen === 1 && validateBusinessQuestions()) {
                                        setScreen(currScreen => currScreen + 1);
                                    } else if (screen === 2 && validateProprietorPersonalDetails()) {
                                        setScreen(currScreen => currScreen + 1);
                                    } else if (screen === 3 && validateBeneficialOwners()) {
                                        setScreen(currScreen => currScreen + 1);
                                    } else {
                                        if(formData.proposed_business_name === ""){
                                            toast.error("Proposed business name is required!")
                                        }else if(formData.ghana_card_number === ""){
                                            toast.error("Ghana Card number is required!")
                                        }else if(formData.nature_of_business === ""){
                                            toast.error("Nature of business is required!")
                                        }else if(formData.digital_address_of_office === ""){
                                            toast.error("Digital Address of office is required!")
                                        }else if (formData.house_number_for_office === "") {
                                            toast.error("House Number for office is required!");
                                        } else if (formData.street_name_for_office === "") {
                                            toast.error("Street Name for office is required!");
                                        } else if (formData.city_of_office === "") {
                                            toast.error("City of office is required!");
                                        } else if (formData.district_of_office === "") {
                                            toast.error("District of office is required!");
                                        } else if (formData.region_of_office === "") {
                                            toast.error("Region of office is required!");
                                        }else if (formData.nature_of_office_space === "") {
                                            toast.error("Nature of office space is required!");
                                        }else if (formData.postal_address_of_office === "") {
                                            toast.error("Postal Address of office is required!");
                                        } else if (formData.office_phone_number === "") {
                                            toast.error("Office Phone Number is required!");
                                        } else if (formData.office_email_address === "") {
                                            toast.error("Office Email Address is required!");
                                        } else if (formData.projected_revenue === "") {
                                            toast.error("Projected Revenue is required!");
                                        } else if (formData.projected_employees_number === "") {
                                            toast.error("Projected Employees Number is required!");
                                        }else if (formData.last_name === "") {
                                            toast.error("Last name is required!");
                                        }else if (formData.gender === "") {
                                            toast.error("Gender is required!");
                                        } else if (formData.dob === "") {
                                            toast.error("Date of Birth is required!");
                                        } else if (formData.nationality === "") {
                                            toast.error("Nationality is required!");
                                        } else if (formData.occupation === "") {
                                            toast.error("Occupation is required!");
                                        } else if (formData.phone_number === "") {
                                            toast.error("Phone Number is required!");
                                        } else if (formData.email_address === "") {
                                            toast.error("Email Address is required!");
                                        } else if (formData.digital_address === "") {
                                            toast.error("Digital Address is required!");
                                        } else if (formData.house_number === "") {
                                            toast.error("House Number is required!");
                                        } else if (formData.street_name === "") {
                                            toast.error("Street Name is required!");
                                        } else if (formData.city === "") {
                                            toast.error("City is required!");
                                        } else if (formData.district === "") {
                                            toast.error("District is required!");
                                        } else if (formData.region === "") {
                                            toast.error("Region is required!");
                                        } else if (formData.country === "") {
                                            toast.error("Country is required!");
                                        }
                                    }
                                }
                            }}
                            className='btn btn-success ms-1'
                        >
                            {isLastScreen() ? "Submit" : "Next"}
                        </button>

                    </div>
                )}
                
            </div>

            <button type="button" id='hiddenModalTrigger' className="btn btn-success d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Type of Business
            </button>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header d-flex align-items-center justify-content-between">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Business Type</h1>
                        <a href="/" ><Home style={{color: '#008000'}} /></a>
                    </div>
                    <div className="modal-body">
                        <h3>What type of business would you like to register?</h3>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-warning"  onClick={() => handleFormTypeSelection('sole')} data-bs-dismiss="modal" >Sole Proprietorship</button>
                        <button type="button" className="btn btn-success" onClick={() => handleFormTypeSelection('partnership')} data-bs-dismiss="modal">Partnership</button>
                    </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}