import React from 'react'

export default function CompanyAuditorDetials({ formData, setFormData }) {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
  return (
    <div className='px-2'>
        <h3>Company Auditor Details</h3>
        <p>Kindly fill this section where you have a qualified applicant. EKS Consults can assist get a qualified Auditor</p>
        <form>
            <div className="row shadow p-2 rounded-4">
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Tin </label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='tin_of_auditor'
                        value={formData.tin_of_auditor}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Ghana Card Number</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='ghana_card_number_of_auditor'
                        value={formData.ghana_card_number_of_auditor}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Name</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='name_auditor'
                        value={formData.name_auditor}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Digital Address</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='auditor_digital_address'
                        value={formData.auditor_digital_address}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Postal Address</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='auditor_postal_address'
                        value={formData.auditor_postal_address}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Name or House Number</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='auditor_name_or_house_number'
                        value={formData.auditor_name_or_house_number}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Street Name</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='auditor_street_name'
                        value={formData.auditor_street_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">City</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='auditor_city'
                        value={formData.auditor_city}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">District</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='auditor_district'
                        value={formData.auditor_district}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Region</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='auditor_region'
                        value={formData.auditor_region}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Mobile Number</label>
                    <input 
                        type="number" 
                        className='form-control'
                        name='auditor_mobile_number'
                        value={formData.auditor_mobile_number}
                        onChange={handleInputChange}
                        required
                    />
                </div>
            </div>
        </form>
    </div>
  )
}
