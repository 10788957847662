import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, serverTimestamp, child, get, remove } from "firebase/database";
import ToastService from "react-material-toast";

const toast = ToastService.new({
  place: "topRight",
  duration: 1,
  maxCount: 1,
});

const firebaseConfig = {
  apiKey: "AIzaSyD3drVXw-l6ImTNGCErD4hLpNmQZIs5ai0",
  authDomain: "eks-consults.firebaseapp.com",
  databaseURL: "https://eks-consults-default-rtdb.firebaseio.com",
  projectId: "eks-consults",
  storageBucket: "eks-consults.appspot.com",
  messagingSenderId: "599131327364",
  appId: "1:599131327364:web:752394c945b86c805b2bd1",
  measurementId: "G-RNMEGVKTDL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);



export const writeSoleProprietorship = (id, proposed_business_name, tin_number, ghana_card_number, nature_of_business, date_of_commencement, digital_address_of_office, house_number_for_office, street_name_for_office, city_of_office, district_of_office, region_of_office, nature_of_office_space, other_nature_of_office_space, name_of_landlord, postal_address_of_office, office_phone_number, office_email_address, projected_revenue, projected_employees_number, first_name, last_name, middle_name, former_name, gender, dob, nationality, occupation, phone_number, email_address, digital_address, house_number, street_name, city, district, region, country, mother_maiden_name) => {
        set(ref(db, 'sole/' + id), {
            proposed_business_name: proposed_business_name,
            tin_number: tin_number,
            ghana_card_number: ghana_card_number,
            nature_of_business: nature_of_business,
            date_of_commencement: date_of_commencement,
            digital_address_of_office: digital_address_of_office,
            house_number_for_office: house_number_for_office,
            street_name_for_office: street_name_for_office,
            city_of_office: city_of_office,
            district_of_office: district_of_office,
            region_of_office: region_of_office,
            nature_of_office_space: nature_of_office_space,
            other_nature_of_office_space: other_nature_of_office_space,
            name_of_landlord: name_of_landlord,
            postal_address_of_office: postal_address_of_office,
            office_phone_number: office_phone_number,
            office_email_address: office_email_address,
            projected_revenue: projected_revenue,
            projected_employees_number: projected_employees_number,
            first_name: first_name,
            last_name: last_name,
            middle_name: middle_name,
            former_name: former_name,
            gender: gender,
            dob: dob,
            nationality: nationality,
            occupation: occupation,
            phone_number: phone_number,
            email_address: email_address,
            digital_address: digital_address,
            house_number: house_number,
            street_name: street_name,
            city: city,
            district: district,
            region: region,
            country: country,
            mother_maiden_name: mother_maiden_name,
            user_id: id,
            created_at: serverTimestamp()
        });
}

export const writePartnership = (id, proposed_business_name, tin_number, ghana_card_number, nature_of_business, date_of_commencement, digital_address_of_office, house_number_for_office, street_name_for_office, city_of_office, district_of_office, region_of_office, nature_of_office_space, other_nature_of_office_space, name_of_landlord, postal_address_of_office, office_phone_number, office_email_address, projected_revenue, projected_employees_number, first_name, last_name, middle_name, former_name, gender, dob, nationality, occupation, phone_number, email_address, digital_address, house_number, street_name, city, district, region, country, mother_maiden_name, beneficial_owner, tin_of_beneficial_owner, id_type_of_beneficial_owner, id_number_of_beneficial_owner, place_of_issue, date_of_issue, expiry_date, country_of_issue, full_name_of_beneficial_owner, gender_of_beneficial_owner, dob_of_beneficial_owner, mother_maiden_name_of_beneficial_owner) => {
    set(ref(db, 'part/' + id), {
        proposed_business_name: proposed_business_name,
        tin_number: tin_number,
        ghana_card_number: ghana_card_number,
        nature_of_business: nature_of_business,
        date_of_commencement: date_of_commencement,
        digital_address_of_office: digital_address_of_office,
        house_number_for_office: house_number_for_office,
        street_name_for_office: street_name_for_office,
        city_of_office: city_of_office,
        district_of_office: district_of_office,
        region_of_office: region_of_office,
        nature_of_office_space: nature_of_office_space,
        other_nature_of_office_space: other_nature_of_office_space,
        name_of_landlord: name_of_landlord,
        postal_address_of_office: postal_address_of_office,
        office_phone_number: office_phone_number,
        office_email_address: office_email_address,
        projected_revenue: projected_revenue,
        projected_employees_number: projected_employees_number,
        first_name: first_name,
        last_name: last_name,
        middle_name: middle_name,
        former_name: former_name,
        gender: gender,
        dob: dob,
        nationality: nationality,
        occupation: occupation,
        phone_number: phone_number,
        email_address: email_address,
        digital_address: digital_address,
        house_number: house_number,
        street_name: street_name,
        city: city,
        district: district,
        region: region,
        country: country,
        mother_maiden_name: mother_maiden_name,
        beneficial_owner: beneficial_owner,
        tin_of_beneficial_owner: tin_of_beneficial_owner,
        id_type_of_beneficial_owner: id_type_of_beneficial_owner,
        id_number_of_beneficial_owner: id_number_of_beneficial_owner,
        place_of_issue: place_of_issue,
        date_of_issue: date_of_issue,
        expiry_date: expiry_date,
        country_of_issue: country_of_issue,
        full_name_of_beneficial_owner: full_name_of_beneficial_owner,
        gender_of_beneficial_owner: gender_of_beneficial_owner,
        dob_of_beneficial_owner: dob_of_beneficial_owner,
        mother_maiden_name_of_beneficial_owner: mother_maiden_name_of_beneficial_owner,
        user_id: id,
        created_at: serverTimestamp()
    });
}

// export const writeCompany = (id, proposed_company_name, name_of_presenting_officer, tin_number, ghana_card_number, nature_of_business, date_of_commencement, digital_address_of_office, house_number_for_office, street_name_for_office, city_of_office, district_of_office, region_of_office, nature_of_office_space, other_nature_of_office_space, name_of_landlord, postal_address_of_office, office_phone_number, office_email_address, eligibility_as_director, insolventCompany, fraudConviction, insolventCompanyConviction, eligibility_as_secretary, first_name, last_name, middle_name, former_name, gender, dob, place_of_birth, nationality, occupation, phone_number, email_address, director_tin_number, director_ghana_card_number, digital_address, house_number, street_name, city, district, region, country, mother_maiden_name, tin_of_auditor, ghana_card_number_of_auditor, name_auditor, auditor_digital_address, auditor_postal_address, auditor_name_or_house_number, auditor_street_name, auditor_city, auditor_district, auditor_region, auditor_mobile_number) => {
//     set(ref(db, 'comp/' + id), {
//         proposed_company_name: proposed_company_name,
//         name_of_presenting_officer: name_of_presenting_officer,
//         tin_number: tin_number,
//         ghana_card_number: ghana_card_number,
//         nature_of_business: nature_of_business,
//         date_of_commencement: date_of_commencement,
//         digital_address_of_office: digital_address_of_office,
//         house_number_for_office: house_number_for_office,
//         street_name_for_office: street_name_for_office,
//         city_of_office: city_of_office,
//         district_of_office: district_of_office,
//         region_of_office: region_of_office,
//         nature_of_office_space: nature_of_office_space,
//         other_nature_of_office_space: other_nature_of_office_space,
//         name_of_landlord: name_of_landlord,
//         postal_address_of_office: postal_address_of_office,
//         office_phone_number: office_phone_number,
//         office_email_address: office_email_address,
//         eligibility_as_director: eligibility_as_director,
//         insolventCompany: insolventCompany,
//         fraudConviction: fraudConviction,
//         insolventCompanyConviction: insolventCompanyConviction,
//         eligibility_as_secretary: eligibility_as_secretary,
//         first_name: first_name,
//         last_name: last_name,
//         middle_name: middle_name,
//         former_name: former_name,
//         gender: gender,
//         dob: dob,
//         place_of_birth: place_of_birth,
//         nationality: nationality,
//         occupation: occupation,
//         phone_number: phone_number,
//         email_address: email_address,
//         director_tin_number: director_tin_number,
//         director_ghana_card_number: director_ghana_card_number,
//         digital_address: digital_address,
//         house_number: house_number,
//         street_name: street_name,
//         city: city,
//         district: district,
//         region: region,
//         country: country,
//         mother_maiden_name: mother_maiden_name,
//         tin_of_auditor: tin_of_auditor,
//         ghana_card_number_of_auditor: ghana_card_number_of_auditor,
//         name_auditor: name_auditor,
//         auditor_digital_address: auditor_digital_address,
//         auditor_postal_address: auditor_postal_address,
//         auditor_name_or_house_number: auditor_name_or_house_number,
//         auditor_street_name: auditor_street_name,
//         auditor_city: auditor_city,
//         auditor_district: auditor_district,
//         auditor_region: auditor_region,
//         auditor_mobile_number: auditor_mobile_number,
//         user_id: id,
//         created_at: serverTimestamp()
//     });
// }

export const writeCompany = (
    id,
    proposed_company_name,
    name_of_presenting_officer,
    tin_number,
    ghana_card_number,
    nature_of_business,
    date_of_commencement,
    digital_address_of_office,
    house_number_for_office,
    street_name_for_office,
    city_of_office,
    district_of_office,
    region_of_office,
    nature_of_office_space,
    other_nature_of_office_space,
    name_of_landlord,
    postal_address_of_office,
    office_phone_number,
    office_email_address,
    eligibility_as_director,
    insolventCompany,
    fraudConviction,
    insolventCompanyConviction,
    eligibility_as_secretary,
    first_name,
    last_name,
    middle_name,
    former_name,
    gender,
    dob,
    place_of_birth,
    nationality,
    occupation,
    phone_number,
    email_address,
    director_tin_number,
    director_ghana_card_number,
    digital_address,
    house_number,
    street_name,
    city,
    district,
    region,
    country,
    mother_maiden_name,
    tin_of_auditor,
    ghana_card_number_of_auditor,
    name_auditor,
    auditor_digital_address,
    auditor_postal_address,
    auditor_name_or_house_number,
    auditor_street_name,
    auditor_city,
    auditor_district,
    auditor_region,
    auditor_mobile_number
) => {
    const data = {
        proposed_company_name,
        name_of_presenting_officer,
        tin_number,
        ghana_card_number,
        nature_of_business,
        date_of_commencement,
        digital_address_of_office,
        house_number_for_office,
        street_name_for_office,
        city_of_office,
        district_of_office,
        region_of_office,
        nature_of_office_space,
        other_nature_of_office_space,
        name_of_landlord,
        postal_address_of_office,
        office_phone_number,
        office_email_address,
        eligibility_as_director,
        insolventCompany,
        fraudConviction,
        insolventCompanyConviction,
        eligibility_as_secretary,
        first_name,
        last_name,
        middle_name,
        former_name,
        gender,
        dob,
        place_of_birth,
        nationality,
        occupation,
        phone_number,
        email_address,
        director_tin_number,
        director_ghana_card_number,
        digital_address,
        house_number,
        street_name,
        city,
        district,
        region,
        country,
        mother_maiden_name,
        user_id: id,
        created_at: serverTimestamp()
    };

    // Add optional fields if they exist
    if (tin_of_auditor) data.tin_of_auditor = tin_of_auditor;
    if (ghana_card_number_of_auditor) data.ghana_card_number_of_auditor = ghana_card_number_of_auditor;
    if (name_auditor) data.name_auditor = name_auditor;
    if (auditor_digital_address) data.auditor_digital_address = auditor_digital_address;
    if (auditor_postal_address) data.auditor_postal_address = auditor_postal_address;
    if (auditor_name_or_house_number) data.auditor_name_or_house_number = auditor_name_or_house_number;
    if (auditor_street_name) data.auditor_street_name = auditor_street_name;
    if (auditor_city) data.auditor_city = auditor_city;
    if (auditor_district) data.auditor_district = auditor_district;
    if (auditor_region) data.auditor_region = auditor_region;
    if (auditor_mobile_number) data.auditor_mobile_number = auditor_mobile_number;

    set(ref(db, 'comp/' + id), data);
}

export const handleSoleView = async (id, setSelectedData) => {
    const dataRef = child(ref(db), `sole/${id}`);
    try {
        const snapshot = await get(dataRef);
        if (snapshot.exists()) {
            setSelectedData(snapshot.val());
        } else {
            console.log("No data available");
        }
    } catch (error) {
        console.error("Error fetching data by ID:", error);
    }
};

export const handlePartView = async (id, setSelectedData) => {
    const dataRef = child(ref(db), `part/${id}`);
    try {
        const snapshot = await get(dataRef);
        if (snapshot.exists()) {
            setSelectedData(snapshot.val());
        } else {
            console.log("No data available");
        }
    } catch (error) {
        console.error("Error fetching data by ID:", error);
    }
};

export const handleCompView = async (id, setSelectedData) => {
    const dataRef = child(ref(db), `comp/${id}`);
    try {
        const snapshot = await get(dataRef);
        if (snapshot.exists()) {
            setSelectedData(snapshot.val());
        } else {
            console.log("No data available");
        }
    } catch (error) {
        console.error("Error fetching data by ID:", error);
    }
};

export const deleteSoleDataById = async (user_id) => {
    const db = getDatabase();
    const reference = ref(db, `sole/${user_id}`);
  
    try {
        await remove(reference);
        toast.success('Data deleted successfully');
    } catch (error) {
        console.error('Error deleting data:', error.message);
        toast.error('Failed to delete data');
    }
};

export const deletePartDataById = async (user_id) => {
    const db = getDatabase();
    const reference = ref(db, `part/${user_id}`);
  
    try {
        await remove(reference);
        toast.success('Data deleted successfully');
    } catch (error) {
        console.error('Error deleting data:', error.message);
        toast.error('Failed to delete data');
    }
};

export const deleteCompDataById = async (user_id) => {
    const db = getDatabase();
    const reference = ref(db, `comp/${user_id}`);
  
    try {
        await remove(reference);
        toast.success('Data deleted successfully');
    } catch (error) {
        console.error('Error deleting data:', error.message);
        toast.error('Failed to delete data');
    }
};