import React from 'react'
import logo from '../assets/img/logo/logo.png';

export default function NavBarDash() {
  return (
    <nav className="navbar navbar-expand-lg bg-white shadow sticky-top">
      <div className="container">
        <a className="navbar-brand me-5" href="/">
            <img src={logo} width={80} className='img-fluid' alt="" />
            <span className='fw-bold h6' >Admin Dashboard</span>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="/admin/sole-proprietorship">Sole Proprietorship</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/admin/partnership">Partnership</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/admin/company">Company Registration</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
