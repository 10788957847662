import React from 'react';
import logo from '../assets/img/logo/logo.png';

export default function ThankYou() {
    const currentYear = new Date().getFullYear();
  return (
    <div className="App">
      <header className="App-header px-3">
        <div className="container">
            <p>EKS Consults</p>
            <img src={logo} className='img-fluid App-logo' alt="logo" />
            <p>Thank you for using EKS Consults!</p>
            <p>Please check your email for a copy of the details you provided during registration. If it's not in your inbox, kindly check your spam or junk folder.</p>
            <a href='/' className="btn btn-success mb-3">Return to Homepage</a>
            <p>EKS Consults &copy; {currentYear} All Rights Reserved</p>    
        </div>
      </header>
    </div>
  )
}
