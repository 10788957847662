import React from 'react'

export default function BeneficialOwners({ formData, setFormData }) {
    const sex = [
        "Male",
        "Female"
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Handle radio button state
        if (name === 'gender_of_beneficial_owner') {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else {
            // Handle text input state
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };
  return (
    <div className='px-2'>
        <h3>Beneficial Owners</h3>
        <p>Kindly fill this section if you are registering a Partnership of Company</p>
        <form>
            <div className="row shadow p-2 rounded-4">
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Beneficial Owner</label>
                    <input 
                        type="text" 
                        className='form-control' 
                        name='beneficial_owner' 
                        value={formData.beneficial_owner} 
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Tin of Beneficial Owner</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='tin_of_beneficial_owner'
                        value={formData.tin_of_beneficial_owner}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">ID Type of Beneficial Owner</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='id_type_of_beneficial_owner'
                        value={formData.id_type_of_beneficial_owner}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">ID Number of Beneficial Owner</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='id_number_of_beneficial_owner'
                        value={formData.id_number_of_beneficial_owner}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Place of Issue of ID</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='place_of_issue'
                        value={formData.place_of_issue}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Date of Issue of ID</label>
                    <input 
                        type="date" 
                        className='form-control'
                        name='date_of_issue'
                        value={formData.date_of_issue}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Date of Expiry of ID</label>
                    <input 
                        type="date" 
                        className='form-control'
                        name='expiry_date'
                        value={formData.expiry_date}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Country of issue of ID</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='country_of_issue'
                        value={formData.country_of_issue}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Full name of Beneficial Owner (First name, Middle name, Last name)</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='full_name_of_beneficial_owner'
                        value={formData.full_name_of_beneficial_owner}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Gender</label>
                    {sex.map((sx) => (
                        <div className="form-check" key={sx}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name='gender_of_beneficial_owner'
                                value={sx}
                                checked={formData.gender_of_beneficial_owner === sx}
                                onChange={handleInputChange}
                                required
                            />
                            <label className="form-check-label">
                                {sx}
                            </label>
                        </div>
                    ))}
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Date of Birth of Beneficial Owner</label>
                    <input 
                        type="date" 
                        className='form-control'
                        name='dob_of_beneficial_owner'
                        value={formData.dob_of_beneficial_owner}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Mother's maiden name of Beneficial Owner (First name,  Last name)</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='mother_maiden_name_of_beneficial_owner'
                        value={formData.mother_maiden_name_of_beneficial_owner}
                        onChange={handleInputChange}
                        required
                    />
                </div>
            </div>
        </form>
    </div>
  )
}
