import React from 'react'

export default function CoverNoteSection({formData, setFormData}) {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

  return (
    <div className='px-2'>
        <h3>Cover Note Section</h3>
        <p>This section contains key requirement for registering your business</p>
        <form >
            <div className="row shadow p-2 rounded-4">
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Proposed Business Name</label>
                    <input 
                        type="text" 
                        className='form-control' 
                        name='proposed_business_name' 
                        value={formData.proposed_business_name} 
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Tin Number</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='tin_number'
                        value={formData.tin_number}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Ghana Card Number</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='ghana_card_number'
                        value={formData.ghana_card_number}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Nature of Business (What you will be doing)</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='nature_of_business'
                        value={formData.nature_of_business}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Date of Commencement</label>
                    <input 
                        type="date" 
                        className='form-control'
                        name='date_of_commencement'
                        value={formData.date_of_commencement}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
        </form>
    </div>
  )
}
