import React from 'react'

export default function ProprietorPersonalDetails({ formData, setFormData }) {
    const sex = [
        "Male",
        "Female"
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Handle radio button state
        if (name === 'gender') {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else {
            // Handle text input state
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };
  return (
    <div className='px-2'>
        <h3>Proprietor Personal Details Section</h3>
        <p>The questions here relates to the business owner</p>
        <form>
            <div className="row shadow p-2 rounded-4">
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">First Name</label>
                    <input 
                        type="text" 
                        className='form-control' 
                        name='first_name' 
                        value={formData.first_name} 
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Middle Name</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='middle_name'
                        value={formData.middle_name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Last Name</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='last_name'
                        value={formData.last_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Former Name (if any)</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='former_name'
                        value={formData.former_name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Gender</label>
                    {sex.map((sx) => (
                        <div className="form-check" key={sx}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name='gender'
                                value={sx}
                                checked={formData.gender === sx}
                                onChange={handleInputChange}
                                required
                            />
                            <label className="form-check-label">
                                {sx}
                            </label>
                        </div>
                    ))}
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Date of Birth</label>
                    <input 
                        type="date" 
                        className='form-control'
                        name='dob'
                        value={formData.dob}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Nationality</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='nationality'
                        value={formData.nationality}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Occupation</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='occupation'
                        value={formData.occupation}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Phone Number</label>
                    <input 
                        type="number" 
                        className='form-control'
                        name='phone_number'
                        value={formData.phone_number}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Email Address</label>
                    <input 
                        type="email" 
                        className='form-control'
                        name='email_address'
                        value={formData.email_address}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Digital Address</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='digital_address'
                        value={formData.digital_address}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">House Number</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='house_number'
                        value={formData.house_number}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Street Name</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='street_name'
                        value={formData.street_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">City</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='city'
                        value={formData.city}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">District</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='district'
                        value={formData.district}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Region</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='region'
                        value={formData.region}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Country</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='country'
                        value={formData.country}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="col-sm-12 col-md-6 mb-3">
                    <label className="form-label">Mother's Maiden Name (Complete if you do not have TIN)</label>
                    <input 
                        type="text" 
                        className='form-control'
                        name='mother_maiden_name'
                        value={formData.mother_maiden_name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
            </div>
        </form>
    </div>
  )
}
