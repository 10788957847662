import './App.css';
import { Routes, Route } from 'react-router-dom';
// import UnderConstruction from './screens/UnderConstruction';
import Home from './screens/Home';
import About from './screens/About';
import ScrollToTop from './components/ScrollToTop';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Services from './screens/Services';
import Contact from './screens/Contact';
import { useEffect, useState } from 'react';
import Preloader from './components/Preloader';
import SoleMultiForm from './screens/SoleMultiForm';
import logo from './assets/img/logo/logo.png'
import ThankYou from './screens/ThankYou';
import CompanyMultiForm from './screens/CompanyMultiForm';
import SoleProprietorship from './Dashboard/SoleProprietorship';
import Partnership from './Dashboard/Partnership';
import Company from './Dashboard/Company';
import Dashboard from './Dashboard/Dashboard';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);

  return (
    <div className="">
      {loading && <Preloader />}
      <Routes>
        {/* <Route path='/' element={<UnderConstruction/>} /> */}
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/services' element={<Services/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/sole-proprietor-and-partnership' element={<SoleMultiForm/>} />
        <Route path='/company-registration' element={<CompanyMultiForm/>} />
        <Route path='/confirmation' element={<ThankYou/>} />
        <Route path='/admin' element={<Dashboard/>} />
        <Route path='/admin/sole-proprietorship' element={<SoleProprietorship/>} />
        <Route path='/admin/partnership' element={<Partnership/>} />
        <Route path='/admin/company' element={<Company/>} />
      </Routes>
      <ScrollToTop/>
      <FloatingWhatsApp 
        phoneNumber="+233248171348" 
        accountName="ESK Consults" 
        avatar={logo}
        statusMessage="Available 24/7"
        allowEsc
        allowClickAway
        notification
        notificationSound={true}
        darkMode={true}
      />
    </div>
  );
}

export default App;
