import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

export default function About() {
  return (
    <div>
        <NavBar/>
        <div className="about-banner mb-4">
          <h2>About Us</h2>
        </div>
        <div className="container">
            <p className=''>
            EKS Consults is a consulting firm that specializes in providing the requisite documentation and processes for businesses both financially and operationally. We assist from setup stage, ensuring availability of needed financials in assessing your performance, providing control measures to mitigate risks and migrating your business to the digitalized world. Its target market consists mainly of small to medium businesses.
            </p>
            <div className="row mb-4">
              <div className="col-sm-12 col-md-6 mb-3">
                <div className="card border-0 shadow">
                  <div className="card-body">
                    <h3>Mission</h3>
                    <p>
                      EKS Consult's mission is to become the link in switching organisations to the smart way and to contribute to the growth of businesses through the smart approach.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 mb-3">
                <div className="card border-0 shadow">
                  <div className="card-body">
                    <h3>Vision</h3>
                    <p>
                      To become the point of call for small-scale businesses in solving their business equations.<i>We help solve that “find X” question</i>
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="team">
              {/* <h2>The Team</h2> */}
            </div>
        </div>
        <Footer/>
    </div>
  )
}
