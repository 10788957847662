import React from 'react';
import logo from '../assets/img/logo/logo.png';
import { ArrowRightOutlined } from '@mui/icons-material';

export default function Footer() {
    const currentYear = new Date().getFullYear();
  return (
    <div className="footer" >
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-sm-6 col-md-4 mb-3">
                    <div className="d-flex align-items-center">
                        <img src={logo} className='img-fluid' width={100} alt="" />
                        <div>
                            <h3 className='text-white mb-0' >EKS Consults</h3>
                            <p className='text-white mb-0' >Together, we find what you've been looking for.</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4 mb-3">
                    <h3 className='text-white text-decoration-underline'>Quick Links</h3>
                    <a href="/" className='text-white text-decoration-none'><h6><ArrowRightOutlined/>Privacy Policy</h6></a>
                    <a href="/" className='text-white text-decoration-none'><h6><ArrowRightOutlined/>Terms & Conditions</h6></a>
                </div>
                <div className="col-sm-6 col-md-4 mb-3">
                <h3 className='text-white text-decoration-underline'>Contact Us</h3>
                    <a href="/" className='text-white text-decoration-none'><h6><ArrowRightOutlined/>Afienya Mateheko</h6></a>
                    <a href="mailto:info@eksconsults.com" className='text-white text-decoration-none'><h6><ArrowRightOutlined/>info@eksconsults.com</h6></a>
                    <a href="mailto:eksbus@gmail.com" className='text-white text-decoration-none'><h6><ArrowRightOutlined/>eksbus@gmail.com</h6></a>
                    <a href="tel:+233248171348" className='text-white text-decoration-none'><h6><ArrowRightOutlined/>+233 248 171 348</h6></a>
                </div>
            </div>
        </div>
        <hr style={{color: '#fff'}} />
        <div className="container copyright">
        <div className="row text-center text-md-left">
                <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start">
                    <h6 className='text-white'>EKS Consults &copy; {currentYear} All Rights Reserved</h6>
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end">
                    <h6><span className='text-white'>Developed by: </span><span><a className='text-decoration-none' style={{color: '#008000'}} href="https://christian.techieszon.com/">Christian Edem Kpegah</a></span></h6>
                </div>
            </div>
        </div>
    </div>
  )
}
