import React from 'react';
import logo from '../assets/img/logo/logo.png';
import coverNote from '../assets/docs/COVER_NOTE_Proprietor.pdf';
import formA from '../assets/docs/Form_A_Sole_Proprietor.pdf';
import taxPayerReg from '../assets/docs/Taxpayer-registration-form-individual.pdf'

export default function NavBar() {
  return (
    <nav className="navbar navbar-expand-lg bg-white shadow sticky-top">
      <div className="container">
        <a className="navbar-brand me-5" href="/">
            <img src={logo} width={80} className='img-fluid' alt="" />
            <span className='fw-bold h6' >EKS Consults</span>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about">About Us</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/services">Services</a>
            </li>
            <li className="nav-item dropdown">
              <button className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                Registration Forms
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/sole-proprietor-and-partnership">Sole Proprietor/Partnership Form</a></li>
                <li><a className="dropdown-item" href="/company-registration">Company Registration Form</a></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <button className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                Resources
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href={coverNote} target="_blank" rel="noreferrer">Cover Note Proprietor</a></li>
                <li><a className="dropdown-item" href={formA} target="_blank" rel="noreferrer">Form A - Sole Proprietor</a></li>
                <li><a className="dropdown-item" href={taxPayerReg} target="_blank" rel="noreferrer">Taxpayer Registration Form Individual</a></li>
              </ul>
            </li>
          </ul>
          <div className="d-flex">
            <a href="/contact" className="btn btn-warning shadow" >Contact Us</a>
          </div>
        </div>
      </div>
    </nav>
  )
}
